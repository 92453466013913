import React, { useRef } from 'react';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import countries from '@data/countries.json';
import iso3 from '@data/iso3.json';

import { localizedString } from '@languages';
import { isIOS } from 'react-device-detect';
import classes from './CountrySelect.style.module.scss';

const iso3Toiso2Map = Object.entries(iso3).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});

export default function CountrySelect({
  className,
  hasError,
  filter = null,
  value,
  onChange,
  label
}) {
  let newCountries = [...countries];
  if (filter && filter.length > 0) {
    const convertedFilter = filter.map((code) => {
      return iso3Toiso2Map[code];
    });
    newCountries = newCountries.filter(({ code }) => {
      return convertedFilter.includes(code);
    });
  }
  const inputItems = newCountries.map(({ code, name }) => ({ value: code, label: name }));
  const inputRef = useRef();

  return (
    <Downshift
      onChange={(value) => {
        if (value) {
          onChange(value);
          inputRef.current?.select();
        }
      }}
      itemToString={(item) => (item ? item.label : '')}
      selectedItem={value}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
        getRootProps,
        clearSelection,
        openMenu
      }) => {
        const { ref: rootRef, ...rootProps } = getRootProps({}, { suppressRefError: true });
        return (
          <div className={classNames(classes.Select)}>
            {/* Associated control is set in getLabelProps */}
            {/* eslint-disable jsx-a11y/label-has-associated-control */}

            <label {...getLabelProps()} className={label ? null : classes.hidden}>
              {label || localizedString('country')}
            </label>
            <div ref={rootRef} className={classNames('minimal', classes.control, className)}>
              <span className={classes.selectedImageContainer}>
                {selectedItem?.value && inputValue === selectedItem.label && (
                  <img
                    src={`assets-portal/images/flags/${selectedItem.value.toLowerCase()}.png`}
                    alt=""
                  />
                )}
              </span>{' '}
              <input
                ref={inputRef}
                {...rootProps}
                {...getInputProps()}
                onFocus={() => {
                  clearSelection();
                  openMenu();
                }}
              />
              <button type="button" {...getToggleButtonProps()} aria-label="Toggle country menu">
                <img
                  alt=""
                  src="images/icons/png/search-videoid.png"
                  className={classes.searchImage}
                />
              </button>
            </div>
            <ul className={isOpen ? classes.menu : classes.hidden} {...getMenuProps()}>
              {isOpen &&
                inputItems
                  .filter(
                    (item) =>
                      !inputValue || item.label.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .map((item, index) => {
                    const { value, label } = item;
                    return (
                      <li
                        className={classes.countryItem}
                        style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
                        key={value}
                        tabIndex={isIOS ? undefined : 0}
                        {...getItemProps({ item })}
                      >
                        <span className={classes.imageContainer}>
                          <img
                            className={classes.flagImage}
                            src={`assets-portal/images/flags/${value.toLowerCase()}.png`}
                            alt=""
                          />
                        </span>
                        {label}
                      </li>
                    );
                  })}
            </ul>
            {hasError && <div className={classes.hasError}>{hasError}</div>}
          </div>
        );
      }}
    </Downshift>
  );
}

CountrySelect.propTypes = {
  hasError: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  filter: PropTypes.array,
  value: PropTypes.object,
  label: PropTypes.string
};
