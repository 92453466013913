import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@lib/components/v2/Modal';
import { localizedString } from '@languages';
import parse from 'html-react-parser';
import Message from '@lib/components/v2/Message';

/**
 * If card blurry or crop etc.
 */
export const CouldNotRead = (props) => {
  const { idName } = props;
  let title = localizedString('weCouldNotReadYourIDInPhoto');
  if (idName) {
    title = `Sorry, we could not read your ${idName} in the photo.`;
  }
  return (
    <Message {...props} title={title} issue>
      <ul>
        <li>{localizedString('makeSureNoPartsOfCardAreCovered')}</li>
        <li>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
        <li>{localizedString('makeSureIDIsNotBadlyDamaged')}</li>
        <li>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
      </ul>
    </Message>
  );
};

CouldNotRead.propTypes = {
  idName: PropTypes.string
};

/**
 * Wrong document.
 */
export const CouldNotRecognise = (props) => {
  const { idName } = props;
  let title = localizedString('verifyDetails.FLOW_V2_COULD_NOT_RECOGNISE_ALERT_TITLE');
  if (idName) {
    title = `Couldn't recognise your ${idName}`;
  }

  return (
    <Message {...props} title={title} issue>
      {localizedString('verifyDetails.FLOW_V2_COULD_NOT_RECOGNISE_ALERT_DESCRIPTION')}
    </Message>
  );
};

CouldNotRecognise.propTypes = {
  idName: PropTypes.string
};

/**
 * Document not accepted.
 */
export const DocNotAccepted = (props) => {
  return (
    <Message {...props} title="This document is not accepted" issue>
      {parse(localizedString('voiv2.docNotAccepted.description'))}
    </Message>
  );
};

/**
 * Poor Quality Image.
 */
export const PoorQuality = (props) => {
  const { cropped, ...restProps } = props;
  const img = <img data-testid="betterImg-img" alt="" src={cropped} style={{ width: '100%' }} />;
  return (
    <Message {...restProps} title={localizedString('verifyDetails.FLOW_V2_POOR_QUALITY_TITLE')}>
      <p data-testid="betterImg-txt1">
        {parse(localizedString('verifyDetails.FLOW_V2_POOR_QUALITY_DESCRIPTION1'))}
      </p>
      {img}
      <p data-testid="betterImg-txt2">
        {parse(localizedString('verifyDetails.FLOW_V2_POOR_QUALITY_DESCRIPTION2'))}
      </p>
    </Message>
  );
};

PoorQuality.propTypes = {
  cropped: PropTypes.string
};

/**
 * If ID has been expired.
 */
export const ExpiredID = (props) => {
  return (
    <Message {...props} title={localizedString('verifyDetails.FLOW_V2_EXPIRED_ID_HEADING')} issue>
      {parse(localizedString('verifyDetails.FLOW_V2_EXPIRED_ID_DESCRIPTION'))}
    </Message>
  );
};

/**
 * If ID has been expired by more than 2 years.
 */
export const ExpiredIDBy2Years = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('verifyDetails.FLOW_V2_EXPIRED_ID_BY_2_YEARS_HEADING')}
      issue
    >
      {parse(localizedString('verifyDetails.FLOW_V2_EXPIRED_ID_BY_2_YEARS_DESCRIPTION'))}
    </Message>
  );
};

/**
 * If image has poor quality.
 */
export const FaceNotDetectedInId = (props) => {
  const { idName } = props;
  let title = localizedString('weCouldNotRecogniseYourID');
  if (idName) {
    title = `Sorry, we could not recognise your ${idName}.`;
  }
  const style = { color: '#fff' };
  return (
    <Message dark {...props} title={title}>
      <ul>
        <li style={style}>{localizedString('facePictureOnCardShouldBeClear')}</li>
        <li style={style}>{localizedString('makeSureCameraIsNotSmudgedOrDirty')}</li>
        <li style={style}>{localizedString('makeSureIDCleanFromAnyGrime')}</li>
        <li style={style}>{localizedString('makeSureIDIsNotBadlyDamaged')}</li>
        <li style={style}>{localizedString('makeSurePhotoIsInFocusAndTextReadable')}</li>
        <li style={style}>{localizedString('makeSureAllEdgesAreShown')}</li>
      </ul>
    </Message>
  );
};

FaceNotDetectedInId.propTypes = {
  idName: PropTypes.string
};

/**
 * Confirm extracted details
 */
export const ConfirmExtracted = (props) => {
  return <Modal isOpen {...props} heading={localizedString('pleaseConfirmExtractedAreCorrect')} />;
};

/**
 * Confirm consent
 */
export const ConfirmConsent = (props) => {
  return (
    <Modal isOpen {...props} heading={localizedString('pleaesConfirmConsentToCheckDetails')} />
  );
};

/**
 * Confirm going back
 */
export const ConfirmGoBack = (props) => {
  return (
    <Modal isOpen heading={localizedString('app.FLOW_V2_EXIT_SCREEN_TITLE')} {...props}>
      {localizedString('app.FLOW_V2_EXIT_SCREEN_DESCRIPTION_CAPTURE')}
    </Modal>
  );
};

/**
 *  Recapture
 */
export const Recapture = (props) => {
  return (
    <Modal isOpen {...props} heading={localizedString('attendantRequestedYouToRecaptureID')} />
  );
};
/**
 * ID number not editable.
 */
export const IdNumberNotEditable = (props) => {
  return (
    <Message {...props} title={localizedString('idNumberNotEditable')} issue>
      {localizedString('ifIDNumberNotCorrectPleaseTakeAnotherPhoto')}
    </Message>
  );
};

/**
 * ID number not editable.
 */
export const TooManyRetryAttempts = (props) => {
  return (
    <Message {...props} title={localizedString('tooManyAttempts')} issue>
      {localizedString('exceedNumberOfAttemptsToCaptureID')}
      <br />
      <br />
      {localizedString('ifStillIncorrectCanContinueAndWeWillContactYou')}
    </Message>
  );
};

/**
 * Edit too many fields.
 */
export const EditTooManyFields = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('verifyDetails.FLOW_V2_EDIT_TOO_MANY_FIELDS_TITLE')}
      issue
    >
      {localizedString('verifyDetails.FLOW_V2_EDIT_TOO_MANY_FIELDS_DESCRIPTION')}
    </Message>
  );
};

/**
 * Select Another ID
 */
export const SelectAnotherIdException = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('dataCheck.FLOW_V2_DATA_CHECK_SELECT_DIFF_ID_TITLE')}
      issue
    >
      {localizedString('captureSecondDocDesc')}
    </Message>
  );
};
